import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import './assets/styles/_index.scss';
import { ImageModule } from "../common/Image_Module"
import NoImg from "../../images/no-image.png";

const PropertyBanner = (props) => {
    
    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const openPropertyImage = (e, ind) => {
        e.preventDefault()
        setPhotoIndex(ind)
        setIsOpen(true)
    }
    // Property images lightbox

    // Property details images lightbox
    const propertyImages = props?.propertyData?.images
    const propertyImgURL = propertyImages && propertyImages?.every(image => image?.url);
    var propertyLightImages =
        propertyImages && propertyImages.map(img => img.url ? img.url : img.srcUrl)
    // Property details images lightbox

    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
    }
    // Slider settings

    const ValuationProperty = props?.propertyData?.publish == false && props?.propertyData?.status == "valuation";

    return (
        <section className="property-banner-wrapper">
            {
                (!ValuationProperty && propertyImages?.length > 0 && propertyImgURL) ?
                (<Slider className="property-banner-slider d-lg-none" {...settings}>
                    {
                        props?.propertyData?.images.map((propImg) => {
                            return (
                            <div className="property-banner-slide">
                                <div className="property-banner-img-zoom">
                                    <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                                        <ImageModule 
                                            imagename={"property.images.detail2"}
                                            ImageSrc={propImg}
                                            ggfx_results={props?.propertyData?.ggfx_results}
                                            altText={props?.propertyData?.display_address}
                                            strapi_id={props?.propertyData?.id}
                                        />
                                    </a>
                                </div>
                            </div>
                            )
                        })
                    }
                </Slider>)
                :
                (
                <>
                   {(ValuationProperty || props.normalImg) && props?.propertyData?.images?.length > 0 ?
                        ( 
                            <Slider className="property-banner-slider d-lg-none" {...settings}>
                                {
                                    props?.propertyData?.images.map((propImg) => {
                                        return (
                                        <div className="property-banner-slide">
                                            <div className="property-banner-img-zoom">
                                                <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                                                    {<img src={propImg?.srcUrl} alt={props?.propertyData?.display_address} /> }
                                                </a>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </Slider>
                        )
                     :
                    <div className="property-banner-slider d-lg-none">
                        <div className="property-banner-slide">
                            <div className="property-banner-img-zoom">
                                <a href="javascript:void(0)">
                                    <img src={NoImg} alt={props?.propertyData?.display_address} />
                                </a>
                            </div>
                        </div>
                    </div>
                   }

                </>
                )                
            }

            {
                (props?.propertyData?.images?.length > 0 && props?.propertyData?.images[0]?.url && props?.propertyData?.images[1]?.url) ?
                <Container className="d-lg-block d-none">
                    <Row>
                        <Col lg={6} className="pe-0">
                            <div className="property-banner-img img-large">
                                <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                                    <ImageModule 
                                        imagename={"property.images.detailLarge"}
                                        ImageSrc={props?.propertyData?.images[0]}
                                        ggfx_results={props?.propertyData?.ggfx_results}
                                        altText={props?.propertyData?.display_address}
                                        strapi_id={props?.propertyData?.id}
                                    />
                                </a>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="property-banner-grid">
                                {
                                    props?.propertyData?.images?.slice(1, 5).map((propImg, i) => {
                                        return (
                                            <div className={`property-banner-img img-small ${i === 3 ? "img-show" : ""}`}>
                                                <a href="javascript:void(0)" onClick={e => openPropertyImage(e, i+1)}>
                                                    <ImageModule 
                                                        imagename={"property.images.detailSmall"}
                                                        ImageSrc={propImg}
                                                        ggfx_results={props?.propertyData?.ggfx_results}
                                                        altText={props?.propertyData?.display_address}
                                                        strapi_id={props?.propertyData?.id}
                                                    />
                                                </a>
                                                {
                                                    i === 3 &&
                                                    <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)} className="img-show-btn">Show all</a>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
                :
                <Container className="d-lg-block d-none">
                    <Row>
                        {(ValuationProperty || props.normalImg) && props?.propertyData?.images[0]?.srcUrl ?
                            <>
                                <Col lg={6} className="pe-0">
                                    <div className="property-banner-img img-large">
                                        <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                                            <img src={props?.propertyData?.images[0]?.srcUrl} alt={props?.propertyData?.display_address} />
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="property-banner-grid">
                                        <div className="property-banner-img img-small">
                                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 1)}>
                                                <img src={props?.propertyData?.images[1]?.srcUrl} alt={props?.propertyData?.display_address} />
                                            </a>
                                        </div>
                                        <div className="property-banner-img img-small">
                                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 2)}>
                                                <img src={props?.propertyData?.images[2]?.srcUrl} alt={props?.propertyData?.display_address} />
                                            </a>
                                        </div>
                                        <div className="property-banner-img img-small">
                                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 3)}>
                                                <img src={props?.propertyData?.images[3]?.srcUrl} alt={props?.propertyData?.display_address} />
                                            </a>
                                        </div>
                                        <div className="property-banner-img img-small img-show">
                                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 4)}>
                                                <img src={props?.propertyData?.images[4]?.srcUrl} alt={props?.propertyData?.display_address} />
                                               
                                            </a>
                                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)} className="img-show-btn">Show all</a>
                                        </div>
                                    </div>
                                </Col>
                            </>
                            :
                            <>
                                <Col lg={6} className="pe-0">
                                    <div className="property-banner-img img-large">
                                        <a href="javascript:void(0)">
                                            <img src={NoImg} alt={props?.propertyData?.display_address} />
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="property-banner-grid">
                                        <div className="property-banner-img img-small">
                                            <a href="javascript:void(0)">
                                                <img src={NoImg} alt={props?.propertyData?.display_address} />
                                            </a>
                                        </div>
                                        <div className="property-banner-img img-small">
                                            <a href="javascript:void(0)">
                                                <img src={NoImg} alt={props?.propertyData?.display_address} />
                                            </a>
                                        </div>
                                        <div className="property-banner-img img-small">
                                            <a href="javascript:void(0)">
                                                <img src={NoImg} alt={props?.propertyData?.display_address} />
                                            </a>
                                        </div>
                                        <div className="property-banner-img img-small img-show">
                                            <a href="javascript:void(0)">
                                                <img src={NoImg} alt={props?.propertyData?.display_address} />
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </>
                        }
                    </Row>
                </Container>
            }

            {/* Banner image popup */}
            {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImages[photoIndex]}
                    nextSrc={
                        propertyLightImages[
                        (photoIndex + 1) % propertyLightImages.length
                        ]
                    }
                    prevSrc={
                        propertyLightImages[
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                    }
                />
            )}
            {/* Banner image popup */}
        </section>
    )
}

export default PropertyBanner